import React, { useState } from 'react';
import './signin.css'
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import logo from './assets/img/Logo.png'

const SignIn = ({ setAuthenticated }) => {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = async (event) => {
    // event.preventDefault();

    const data = {
      name: username,
      password: password
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      console.log('Response status:', response.status);

      if (response.ok) {
        const jsonResponse = await response.json();
        console.log("Logged in:", jsonResponse);

        // Update the authenticated state here
        

        // Clear input fields by setting value to empty string
        swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Welcome to Aghaz-e-Safer DashedBored',
          showConfirmButton: false,
          timer: 1500
        })
        setUsername('');
        setPassword('');

        // Redirect to the admin dashboard
        setAuthenticated(true);
        history.push("/admin/dashboard");
         // Set authentication status in localStorage
      localStorage.setItem('authenticated', 'true');
      
      // Update the authenticated state here
      setAuthenticated(true);
      } else {
        console.error("Login failed:", response.statusText);
       swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: 'Again check Your Username & password'
        })
        console.log(username);
        console.log(password);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className='back-signin'>
        
        <div className='container signindiv'>
        <h1 className='text-center signin-heading'>SignIn</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="text"
              className='signin-inputs'
              placeholder='Enter User Name'
             
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              autoComplete="off" 
            />
            
            <input
              type="password"
              className='signin-inputs'
              placeholder='Enter Your Password'
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              autoComplete="new-password"
            />
            <div className='text-center'>
            <button type='submit' className='signin-btn'>SignIn</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignIn;



