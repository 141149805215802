import React, { useState, useEffect } from "react";
import './Members.css'
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import "./MessageOutput.css";
import { useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2"; // Import sweetalert2


const AuditsOutput = () => {
  const [ourteamData, setOurteamData] = useState([]);
  const [pdf, setPdf] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/auditImages`);
        const data = await response.json();
        setOurteamData(data.MeetingsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);
  console.log(ourteamData);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/auditPdf`);
        const data = await response.json();
        setPdf(data.MeetingsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);
  console.log(pdf);

  const redirect = () => {
    history.push("/admin/auditsform");
    
  };
  const redirectpdf = () => {
    history.push("/admin/auditspdfform");
    
  };

  const deleteMessage = async (id) => {
    try {
      const result = await fetch(`${process.env.REACT_APP_BASE_URL}/auditImages/${id}`, {
        method: "DELETE",
      });
      if (result.ok) {
        // Message deleted successfully
        setOurteamData(ourteamData.filter((data) => data._id !== id));
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "The message has been deleted.",
        });
      }
    } catch (error) {
      console.log("Something went wrong:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while deleting the message.",
      });
    }
  };

  const confirmDelete = (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This message will be permanently deleted.",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMessage(id);
      }
    });
  };


  const pdfdeleteMessage = async (id) => {
    try {
      const result = await fetch(`${process.env.REACT_APP_BASE_URL}/auditPdf/${id}`, {
        method: "DELETE",
      });
      if (result.ok) {
        // Message deleted successfully
        setPdf(pdf.filter((data) => data._id !== id));
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "The message has been deleted.",
        });
      }
    } catch (error) {
      console.log("Something went wrong:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while deleting the message.",
      });
    }
  };

  const pdfconfirmDelete = (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This message will be permanently deleted.",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        pdfdeleteMessage(id);
      }
    });
  };
  return (
    <>
      <h1 className="text-center">Audits & Certificates</h1>
      <div className="main-member-div">

      {Array.isArray(ourteamData) && ourteamData.length > 0 ? (
        ourteamData.map((data, index) => (
            
          <div className="members-output" key={index}>
            <div>
              <img
                className=" img-fluid output-img"
                src={`${process.env.REACT_APP_BASE_URL}/${data.avatar}`}
                alt=""
              />
            </div>
            <div>
              <h3 className="text-center">{data.title}</h3>
              </div>
            <div className="icons-div-member ">
              <AddCircleOutlineOutlinedIcon
                onClick={redirect}
                style={{ height: "2rem", width: "2rem", cursor: "pointer" }}
              />
              <Link
              to={`/admin/auditUpdate/${data._id}`}
              style={{ color: "white" }}
            >
              <EditOutlinedIcon
                style={{ height: "2rem", width: "2rem", cursor: "pointer" }}
              />
            </Link>
              <DeleteOutlineOutlinedIcon
                onClick={() => confirmDelete(data._id)}
                style={{ height: "2rem", width: "2rem", cursor: "pointer" }}
              />
            </div>
          </div>
         
        ))
      ) : (
        <>
        <p>No messages available.</p>
        <p>May be API error</p>
        <p>No Data</p>
        <h1 onClick={redirect} style={{cursor:"pointer",color:"blue",textDecoration:"underline"}}>Add data</h1>
        </>
      )}
       </div>

       <div className="mt-5" style={{display:"flex",flexWrap:"wrap",gap:"1rem"}}>
       {Array.isArray(pdf) && pdf.length > 0 ? (
        pdf.map((data, index) => (
            
          <div className="members-output" key={index}>
            <div>
              <embed
              type="application/pdf"
              width="100%"
              height="600px" // Adjust this value as needed
              style={{ maxWidth: "100%", maxHeight: "75vh" }}
                // className=" img-fluid output-img"
                src={`${process.env.REACT_APP_BASE_URL}/${data.pdf}`}
                alt=""
              />
            </div>
            <div>
              <h3 className="text-center">{data.title}</h3>
              </div>
            <div className="icons-div-member ">
              <AddCircleOutlineOutlinedIcon
                onClick={redirectpdf}
                style={{ height: "2rem", width: "2rem", cursor: "pointer" }}
              />
              {/* <Link
              to={`/admin/auditUpdate/${data._id}`}
              style={{ color: "white" }}
            >
              <EditOutlinedIcon
                style={{ height: "2rem", width: "2rem", cursor: "pointer" }}
              />
            </Link> */}
              <DeleteOutlineOutlinedIcon
                onClick={() => pdfconfirmDelete(data._id)}
                style={{ height: "2rem", width: "2rem", cursor: "pointer" }}
              />
            </div>
          </div>
         
        ))
      ) : (
        <>
        <p>No Certificate available.</p>
        <p>May be API error</p>
        <p>No Data</p>
        <h1 onClick={redirectpdf} style={{cursor:"pointer",color:"blue",textDecoration:"underline"}}>Add data</h1>
        </>
      )}
       </div>
    </>
  );
};

export default AuditsOutput;
