import React from 'react'
import './Home.css'
import swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';

const TeamRawalpindinForm = () => {
  const history = useHistory();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("designation", data.designation);
      formData.append("phone", data.phone);
      formData.append("avatar", data.avatar[0]); // Assuming you are uploading a single file
  
      let result = await fetch(`${process.env.REACT_APP_BASE_URL}/rawalpindiMembers`, {
        method: "post",
        body: formData,
      });
  
      if (!result.ok) {
        throw new Error("Network response was not ok");
      }
  
      result = await result.json();
      console.log("Form submitted!", result);
  
      swal.fire({
        position: "center",
        icon: "success",
        title: "Member Added",
        showConfirmButton: true,
        timer: 5000,
      });
      // alert("Success! Data is sent");
      reset();
          history.push('/admin/teamrawalpindioutput');
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle the error, show an error message to the user, etc.
    }
  };
  

  return (
    <>
      <div className='home-main-div'>
        <h1 className='text-center'>Rawalpindi Team Member Form</h1>
        <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
          <input
            type='text'
            placeholder='Enter Member Name...'
            {...register("name", { required: true })}
            className='formTitle'
          />
          <span style={{ marginLeft: "2.5rem", color: "red" }}>
            {errors.name?.type === "required" && "Name is required*"}
          </span>
          <input
            type='text'
            placeholder='Enter Member Designation...'
            {...register("designation", { required: true })}
            className='formTitle'
          />
          <span style={{ marginLeft: "2.5rem", color: "red" }}>
            {errors.designation?.type === "required" && "designation is required*"}
          </span>
          <input
            type='text'
            placeholder='Enter Member phone number'
            {...register("phone", { required: true })}
            className='formTitle'
          />
          <span style={{ marginLeft: "2.5rem", color: "red" }}>
            {errors.phone?.type === "required" && "Phone number is required*"}
          </span>
          <input
          type='file'
          name="avatar"
          className='choose-btn'
          {...register("avatar", { required: true })}
        />
        <span style={{ marginLeft: "2.5rem", color: "red" }}>
          {errors.avatar?.type === "required" && "Please Select image"}
        </span>
        {/* Display the uploaded image */}
        {/* {watch("avatar") && <img src={URL.createObjectURL(watch("avatar")[0])} alt='Uploaded Avatar' />} */}

          <div className='text-center mb-4'>
            <button type='submit' className='submit-btn '>
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};


export default TeamRawalpindinForm