import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import Upgrade from "views/Upgrade.js";
import Home from 'views/Home.jsx'
import HomeMessage from "views/HomeMessage";
import Meetings from "views/Meetings";
import HomeMessageOutput from "views/HomeMessageOutput";
import HomeSliderOutput from "views/HomeSliderOutput";
import UpdateMessage from "views/UpdateMessage";
import UpdateSlider from "views/UpdatedSlider";
import FutureProjects from "views/FutureProjects";
import FutureProjectsOutPut from "views/FutureProjectsOutPut";
import UpdateFuturePlains from "views/UpdateFuturePlains";
import TeamLahoreForm from "views/TeamLahoreForm";
import LahoreTeamOutput from "views/LahoreTeamOutput";
import LahoreMembersUpdate from "views/LahoreMembersUpdate";
import MeetingsOutput from "views/MeetingsOutput";
import MeetingsUpdate from "views/MeetingsUpdate";
import MeetingsDetailForm from "views/MeetingsDetailForm";
import MeetingsDetailOutput from "views/MeetingsDetailOutput";
import UpdateMeetingDetails from "views/UpdateMeetingDetails"
import ProjectsForm from "views/ProjectsForm";
import TeamRawalpindinForm from "views/TeamRawalpindinForm";
import RawalpindiTeamOutput from "views/RawalpindiTeamOutput";
import RawalpindiUpdate from "views/RawalpindiUpdate";
import KhawasUpdate from "views/KhawasUpdate";
import TeamKhawasForm from "views/TeamKhawasForm";
import KhawasMemberOutput from "views/KhawasMemberOutput";
import ProjectsUpdate from "views/ProjectsUpdate";
import ProjectsOutput from "views/ProjectsOutput";
import ProjectsDetailsForm from "views/ProjectsDetailsForm";
import ProjectsDetailsOutput from "views/ProjectsDetailsOutput";
import ProjectDetailUpdate from "views/ProjectDetailUpdate";
import PerformanceUpdate from "views/PerformanceUpdate";
import PerformanceReportOutput from "views/PerformanceReportOutput";
import PerformanceReportForm from "views/PerformanceReportForm";
import RwalpidiFundsForm from 'views/RwalpidiFundsForm';
import RawalpindiFundsOutput from "views/RawalpindiFundsOutput";
import LahoreFundsForm from "views/LahoreFundsForm";
import LahoreFundsoutput from "views/LahoreFundsoutput";
import Contactus from "views/Contactus";
import SuggestionOutput from "views/SuggestionOutput"
import MeetingComment from "views/MeetingComment";
import SliderComment from "views/SliderComment";
import RegistrationOutput from "views/RegistrationOutput";
import AuditsOutput from "views/AuditsOutput";
import AuditsForm from "views/AuditsForm";
import AuditsUpdate from "views/AuditsUpdate";
import AuditsPdfForm from "views/AuditsPdfForm";


const dashboardRoutes = [
  {
    path: '/messageUpdate/:id',
    name: "Message Updated Form",
    component: UpdateMessage,
    layout: '/admin',
    excludeFromSidebar: true, // Exclude this route from being rendered in the sidebar
  },
  {
    path: '/auditUpdate/:id',
    name: "Audits Updated Form",
    component: AuditsUpdate,
    layout: '/admin',
    excludeFromSidebar: true, // Exclude this route from being rendered in the sidebar
  },
  {
    path: '/performanceUpdate/:id',
    name: "Performance Updated Form",
    component: PerformanceUpdate,
    layout: '/admin',
    excludeFromSidebar: true, // Exclude this route from being rendered in the sidebar
  },
  {
    path: '/meetingDetailUpdate/:id',
    name: "Meeting Detail Updated Form",
    component: UpdateMeetingDetails,
    layout: '/admin',
    excludeFromSidebar: true, // Exclude this route from being rendered in the sidebar
  },
  {
    path: '/projectDetailUpdate/:id',
    name: "Projects Detail Updated Form",
    component: ProjectDetailUpdate,
    layout: '/admin',
    excludeFromSidebar: true, // Exclude this route from being rendered in the sidebar
  },
  {
    path: '/lahorememberupdate/:id',
    name: "Lahore Member Updated Form",
    component: LahoreMembersUpdate,
    layout: '/admin',
    excludeFromSidebar: true, // Exclude this route from being rendered in the sidebar
  },
  {
    path: '/rawalpindimemberupdate/:id',
    name: "Rawalpindi Member Updated Form",
    component: RawalpindiUpdate,
    layout: '/admin',
    excludeFromSidebar: true, // Exclude this route from being rendered in the sidebar
  },
  {
    path: '/khawasmemberupdate/:id',
    name: "Khawas Member Updated Form",
    component: KhawasUpdate,
    layout: '/admin',
    excludeFromSidebar: true, // Exclude this route from being rendered in the sidebar
  },
  {
    path: '/futureprojectUpdate/:id',
    name: "Future Plains Updated Form",
    component: UpdateFuturePlains,
    layout: '/admin',
    excludeFromSidebar: true, // Exclude this route from being rendered in the sidebar
  },
  {
    path: '/meetingsUpdate/:id',
    name: "Meetings Updated",
    component: MeetingsUpdate,
    layout: '/admin',
    excludeFromSidebar: true, // Exclude this route from being rendered in the sidebar
  },
  {
    path: '/projectsUpdate/:id',
    name: "Projects Updated",
    component: ProjectsUpdate,
    layout: '/admin',
    excludeFromSidebar: true, // Exclude this route from being rendered in the sidebar
  },
  {
    path: '/sliderUpdate/:id',
    name: "Slider Updated Form",
    component: UpdateSlider,
    layout: '/admin',
    excludeFromSidebar: true, // Exclude this route from being rendered in the sidebar
  },
  {
    // upgrade: true,
    path: "/upgrade",
    name: "Upgrade to PRO",
    icon: "nc-icon nc-alien-33",
    component: Upgrade,
    layout: "/admin"
  },
  {
    // upgrade: true,
    path: "/contactus",
    name: "ContactUs Output",
    // icon: "nc-icon nc-alien-33",
    component: Contactus,
    excludeFromSidebar: true,
    layout: "/admin"
  },
  {
    // upgrade: true,
    path: "/suggestion",
    name: "Suggestion Output",
    // icon: "nc-icon nc-alien-33",
    component: SuggestionOutput,
    excludeFromSidebar: true,
    layout: "/admin"
  },
  {
    // upgrade: true,
    path: "/meetingsOutput",
    name: "Meetings output",
    icon: "nc-icon nc-alien-33",
    component: MeetingsOutput,
    layout: "/admin"
  },
  {
    // upgrade: true,
    path: "/meetingComment",
    name: "Meetings Comment output",
    icon: "nc-icon nc-alien-33",
    component: MeetingComment,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/sliderComment",
    name: "Slider Comment output",
    icon: "nc-icon nc-alien-33",
    component: SliderComment,
    excludeFromSidebar: true,
    layout: "/admin",
   
  },
  {
    // upgrade: true,
    path: "/registrationOutput",
    name: "Suggestion output",
    icon: "nc-icon nc-alien-33",
    component: RegistrationOutput,
    excludeFromSidebar: true,
    layout: "/admin"
  },
  {
    // upgrade: true,
    path: "/auditsOutput",
    name: "Audits output",
    icon: "nc-icon nc-alien-33",
    component: AuditsOutput,
    // excludeFromSidebar: true,
    layout: "/admin"
  },
  {
    // upgrade: true,
    path: "/performanceOutput",
    name: "Performance output",
    icon: "nc-icon nc-alien-33",
    component: PerformanceReportOutput,
    layout: "/admin"
  },
  {
    // upgrade: true,
    path: "/rawalpindifundsoutput",
    name: "Rawalpindi Funds output",
    icon: "nc-icon nc-alien-33",
    component: RawalpindiFundsOutput,
    layout: "/admin"
  },
  {
    // upgrade: true,
    path: "/lahorefundsoutput",
    name: "Lahore Funds output",
    icon: "nc-icon nc-alien-33",
    component: LahoreFundsoutput,
    layout: "/admin"
  },
  {
    // upgrade: true,
    path: "/projectsOutput",
    name: "Projects output",
    icon: "nc-icon nc-alien-33",
    component: ProjectsOutput,
    layout: "/admin"
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/user",
    name: "User Profile",
    icon: "nc-icon nc-circle-09",
    component: UserProfile,
    layout: "/admin"
  },
  {
    path: "/homeSlider",
    name: "Home Slider",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    
    component: Home,
    layout: "/admin",
    excludeFromSidebar: true, 
  },
  {
    path: "/homemessage",
    name: "Home Messages",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    
    component: HomeMessage,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/projectform",
    name: "Projects Form",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    
    component: ProjectsForm,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/rawalpindifunds",
    name: "Rawalpindi Funds Form",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    component: RwalpidiFundsForm,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/lahorefunds",
    name: "Lahore Funds Form",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    component: LahoreFundsForm,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/auditsform",
    name: "Audits & Certificate Form",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    component: AuditsForm,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/auditspdfform",
    name: "Audits & Certificate Form",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    component: AuditsPdfForm,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/messageoutput",
    name: "Home MessOutput",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    
    component: HomeMessageOutput,
    layout: "/admin"
  },
  {
    path: "/slideroutput",
    name: "Home Slider Output",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    
    component: HomeSliderOutput,
    layout: "/admin"
  },
  {
    path: "/teamlahoreForm",
    name: "Team Lahore Form",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    
    component: TeamLahoreForm,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/performancereportForm",
    name: "Performance Report Form",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    
    component: PerformanceReportForm,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/teamrawalpindiForm",
    name: "Team Rawalpindi Form",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    
    component: TeamRawalpindinForm,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/teamkhawasForm",
    name: "Team Khawas Form",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    
    component: TeamKhawasForm,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/teamlahoreoutput",
    name: "Team Lahore Output",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    
    component: LahoreTeamOutput,
    layout: "/admin"
  },
  {
    path: "/teamrawalpindioutput",
    name: "Team Rawalpindi Output",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    
    component: RawalpindiTeamOutput,
    layout: "/admin"
  },
  {
    path: "/teamkhawasoutput",
    name: "Team Khawas Output",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    
    component: KhawasMemberOutput,
    layout: "/admin"
  },
  {
    path: "/futureProjects",
    name: "Future Projects",
    icon: "nc-icon nc-circle-09",
    component: FutureProjects,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/futureprojectsoutput",
    name: "Future Projects Output",
    icon: "nc-icon nc-circle-09",
    component: FutureProjectsOutPut,
    layout: "/admin"
  },
  {
    path: "/meetings",
    name: "Meetings",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    component: Meetings,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/meetingsDetailsOutput",
    name: "Meetings Details Output",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    component: MeetingsDetailOutput,
    layout: "/admin",
    excludeFromSidebar: false,
  },
  {
    path: "/projectsDetailsOutput",
    name: "Projects Details Output",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    component: ProjectsDetailsOutput,
    layout: "/admin",
    excludeFromSidebar: false,
  },
  {
    path: "/meetingsDetailsform",
    name: "Meetings Details Form",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    component: MeetingsDetailForm,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/projectsDetailsform",
    name: "Projects Details Form",
    // icon:"BiHomeAlt",
    icon: "nc-icon nc-bank",
    component: ProjectsDetailsForm,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/typography",
    name: "Typography",
    icon: "nc-icon nc-paper-2",
    component: Typography,
    layout: "/admin"
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "nc-icon nc-atom",
    component: Icons,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "nc-icon nc-pin-3",
    component: Maps,
    layout: "/admin"
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "nc-icon nc-bell-55",
    component: Notifications,
    layout: "/admin"
  },
  
];

export default dashboardRoutes;
