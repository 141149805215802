import React, { useState } from "react";
import './Funds.css'
import { useHistory } from 'react-router-dom';

const RawalpindiFundsForm = () => {
  const [dataEntries, setDataEntries] = useState([]);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [income, setIncome] = useState("");
  const [expenses, setExpenses] = useState("");
  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();

    const existingEntryIndex = dataEntries.findIndex((entry) => entry.year === year);

    if (existingEntryIndex !== -1) {
      // Year entry already exists, update month, income, and expenses arrays
      const updatedDataEntries = [...dataEntries];
      const entryToUpdate = updatedDataEntries[existingEntryIndex];
      entryToUpdate.month.push(month);
      entryToUpdate.income.push(income);
      entryToUpdate.expenses.push(expenses);
      setDataEntries(updatedDataEntries);
    } else {
      // Year entry doesn't exist, create a new entry
      const newDataEntry = {
        year,
        month: [month],
        income: [income],
        expenses: [expenses],
      };
      setDataEntries([...dataEntries, newDataEntry]);
    }

    // Clear input fields after submission
    setMonth("");
    setIncome("");
    setExpenses("");
  };

  const sendDataToServer = async () => {
    try {
      for (const entry of dataEntries) {
        const formattedData = {
          year: entry.year,
          month: entry.month,
          income: entry.income,
          expenses: entry.expenses,
        };
  
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/rawalpindiFunds`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formattedData),
        });
  
        if (response.ok) {
          console.log(`Data for year ${entry.year} sent successfully!`);
          history.push('/admin/rawalpindifundsoutput');
        } else {
          console.error(`Error sending data for year ${entry.year}:`, response.status);
        }
      }
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };
  

  return (
    <div className="container mt-5 ">
      <h1 className="text-center">Rawalpindi Funds Form</h1>
      <form className="mt-4 bg-white p-4" onSubmit={handleSubmit}>
        <label className="funds-labels">
          Year:
          <input
          className="funds-input"
            type="text"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
        </label>
        <label className="funds-labels">
          Month:
          <input
          className="funds-input"
            type="text"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          />
        </label>
        <label className="funds-labels">
          Income:
          <input
          className="funds-input"
            type="text"
            value={income}
            onChange={(e) => setIncome(e.target.value)}
          />
        </label>
        <label className="funds-labels">
          Expenses:
          <input
            type="text"
            className="funds-input"
            value={expenses}
            onChange={(e) => setExpenses(e.target.value)}
          />
        </label>
        <button type="submit" className="array-btn">Add Data Entry</button>
      </form>
      
      {dataEntries.map((entry) => (
        <div key={entry.year} className="m-4 showing-data">
          <p>Year: {entry.year}</p>
          <div>
            {entry.month.map((month, monthIndex) => (
              <div key={monthIndex}>
                <p>
                  Month: {month}, Income: {entry.income[monthIndex]}, Expenses:{" "}
                  {entry.expenses[monthIndex]}
                </p>
              </div>
            ))}
          </div>
        </div>
      ))}
      <button onClick={sendDataToServer} className="backend-btn">Send Data to Server</button>
    </div>
  );
};

export default RawalpindiFundsForm;
