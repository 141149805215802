import React,{useEffect, useState} from 'react'
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Swal from "sweetalert2";

const RegistrationOutput = () => {
    const [ourteamData, setOurteamData] = useState([]);

    useEffect(() => {
        async function fetchData() {
          try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/registration`);
            const data = await response.json();
            setOurteamData(data.sliderData);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
    
        fetchData();
      }, []);
      console.log(ourteamData)
      const deleteMessage = async (id) => {
        try {
          const result = await fetch(`${process.env.REACT_APP_BASE_URL}/registration/${id}`, {
            method: "DELETE",
          });
          if (result.ok) {
            // Message deleted successfully
            setOurteamData(ourteamData.filter((data) => data._id !== id));
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "The message has been deleted.",
            });
          }
        } catch (error) {
          console.log("Something went wrong:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while deleting the message.",
          });
        }
      };
      const confirmDelete = (id) => {
        Swal.fire({
          icon: "warning",
          title: "Are you sure?",
          text: "This message will be permanently deleted.",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            deleteMessage(id);
          }
        });
      };
  return (
    <>
    <h1 className='text-center'>Suggestion Output</h1>
    <div>
    {/* <div style={{display:"flex",justifyContent:"space-between",backgroundColor:"black",color:"white",padding:"15px",borderRadius:"6px"}}>
                <div>F_name</div><div>L_name</div><div>E-mail</div><div>Branch</div><div>Message</div><div>Operation</div>
            </div> */}
     <table>
        <thead style={{background:"black",color:"white",padding:"15px", borderRadius:"6px",width: "100vw"}}>
            
            <tr >
        <td style={{padding: "10px"}}>F_Name</td>
        <td style={{padding: "10px"}}>L_Name</td>
        <td style={{padding: "10px"}}>R_Adress</td>
        <td style={{padding: "10px"}}>P_Address</td>
        <td style={{padding: "10px"}}>CNIC</td>
        <td style={{padding: "10px"}}>Education</td>
        <td style={{padding: "10px"}}>Profession</td>
        <td style={{padding: "10px"}}>Blood_Group</td>
        <td style={{padding: "10px"}}>Phone</td>
        <td style={{padding: "10px"}}>Date</td>
        <td style={{padding: "10px"}}>Images</td>
        <td style={{padding: "10px"}}>Operation</td>
    </tr>
            
        </thead>
        
        
    {Array.isArray(ourteamData) && ourteamData.length > 0 ? (
        ourteamData.map((data, index) => (
            
        //   <div  key={index}>
           
        //     <div style={{display:"flex",justifyContent:"space-between",gap:"1rem"}}>
        //       <div className="text-center">{data.fname}</div>
        //       <div className="text-center">{data.lname}</div>
        //       <div className="text-center">{data.email}</div>
        //       <div className=" text-center">{data.branch}</div>
        //       <div className=" text-center">{data.message}</div>
           
        //     <div >
        //       <DeleteOutlineOutlinedIcon
        //         onClick={() => confirmDelete(data._id)}
        //         style={{ height: "2rem", width: "2rem", cursor: "pointer" }}
        //       />
        //     </div>
        //     </div>
        //   </div>
        <tbody key={index}>
            <tr>
                <td style={{padding: "10px"}}>{data.fname}</td>
                <td style={{padding: "10px"}}>{data.lname}</td>
                <td style={{padding: "10px"}}>{data.r_address}</td>
                <td style={{padding: "10px"}}>{data.p_address}</td>
                <td style={{padding: "10px"}}>{data.cnic}</td>
                <td style={{padding: "10px"}}>{data.education}</td>
                <td style={{padding: "10px"}}>{data.profession}</td>
                <td style={{padding: "10px"}}>{data.blood_group}</td>
                <td style={{padding: "10px"}}>{data.phone}</td>
                <td style={{padding: "10px"}}>{data.issue_date}</td>
                <td> <img
                className=" img-fluid "
                src={`${process.env.REACT_APP_BASE_URL}/${data.avatar}`}
                alt=""
                style={{height:"3rem",width:"3rem"}}
              /></td>

               
                <td> 
            <DeleteOutlineOutlinedIcon
              onClick={() => confirmDelete(data._id)}
              style={{ height: "2rem", width: "2rem", cursor: "pointer" }}
            />
            </td>
            </tr>
        </tbody>
         
        ))
      ) : (
        <>
        <p>No messages available.</p>
        <p>May be API error</p>
        <p>No Data</p>
       
        </>
      )}
       </table>   
    </div>


    </>
  )
}

export default RegistrationOutput