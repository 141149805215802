import React, { useEffect, useState } from 'react';
import './Home.css'
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import swal from 'sweetalert2';
import { useForm } from "react-hook-form";


const UpdateMessage = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [messageDetails, setMessageDetails] = useState({
    name: '',
    designation: '',
    message: '',
    avatar: '', // You may want to store the image URL here
  });

  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();

  useEffect(() => {
    getMessageDetails();
  }, []);

  const getMessageDetails = async () => {
    try {
      const result = await fetch(`${process.env.REACT_APP_BASE_URL}/message/${params.id}`);
      const data = await result.json();
      setMessageDetails(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching message details:", error);
    }
  }
  console.log(messageDetails);
  console.log("name",messageDetails.message.name)
  console.log("avatar",messageDetails.message.avatar)

  // const onSubmit = async (data) => {
  //   try {
  //     const result = await fetch(`http://localhost:3000/message/${params.id}`, {
  //       method: 'PUT',
  //       body: JSON.stringify(data), // Use the data parameter instead of individual variables
  //       headers: {
  //         'Content-Type': 'application/json', // Correct the header key
  //       },
  //     });
  
  //     const response = await result.json();
  //     console.log(response);
  
  //     swal.fire({
  //       position: "center",
  //       icon: "success",
  //       title: "Message is Updated successfully!",
  //       showConfirmButton: true,
  //       timer: 5000,
  //     });
  //   } catch (error) {
  //     console.error("Error updating message:", error);
  //   }
  // };
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("designation", data.designation);
      formData.append("message", data.message);
      formData.append("avatar", data.avatar[0]); // Assuming you allow only one file
  
      const result = await fetch(`${process.env.REACT_APP_BASE_URL}/message/${params.id}`, {
        method: 'PUT',
        body: formData,
      });
  
      const response = await result.json();
      console.log(response);
  
      swal.fire({
        position: "center",
        icon: "success",
        title: "Message is Updated successfully!",
        showConfirmButton: true,
        timer: 5000,
      });
      history.push('/admin/messageoutput');
    } catch (error) {
      console.error("Error updating message:", error);
    }
  };
  
  

  if (isLoading) {
    return <div>Loading...</div>; // Display a loading indicator while fetching data
  }

// import React, { useEffect, useState } from 'react';
// import './Home.css'
// import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
// import swal from 'sweetalert2';
// import { useForm } from "react-hook-form";

// const UpdateMessage = () => {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//   } = useForm();

//   const [messageDetails, setMessageDetails] = useState({
//     name: '',
//     designation: '',
//     message: '',
//     avatar: '', // You may want to store the image URL here
//   });

//   const params = useParams();

//   useEffect(() => {
//     getMessageDetails();
//   }, []);

//   const getMessageDetails = async () => {
//     try {
//       const result = await fetch(`http://localhost:3000/message/${params.id}`);
//       const data = await result.json();
//       setMessageDetails(data); // Assuming your API response structure matches the state structure
//     } catch (error) {
//       console.error("Error fetching message details:", error);
//     }
//   }
//   console.log(messageDetails)
//   console.log('name',messageDetails.name)

//   const onSubmit = async (data) => {
//     // Your form submission logic here...
//   };
//   const { id } = useParams(); // Get the id parameter from the route

  return (
    <>
    <div className='home-main-div'>
      <h1 className='text-center'>Update Message Form</h1>
      {messageDetails.name !== '' && messageDetails.designation !== '' && messageDetails.message !=='' && messageDetails.avatar!=='' && (
      // <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        
      <input
            type='text'
            placeholder='Enter The Member Name...'
            defaultValue={messageDetails.message.name} // Set the initial value
            {...register("name", { required: true })}
            className='formTitle'
          />
          
        <span style={{ marginLeft: "2.5rem", color: "red" }}>
          {errors.name?.type === "required" && "Member name Is Required *"}
        </span>
        <input
            type='text'
            placeholder='Enter Member Designation...'
            defaultValue={messageDetails.message.designation} // Set the initial value
            {...register("designation", { required: true })}
            className='formTitle'
          />
        <span style={{ marginLeft: "2.5rem", color: "red" }}>
          {errors.designation?.type === "required" && "Member Designation Is Required *"}
        </span>
        <div>
        <textarea
              className='formTextarea'
              name='textarea'
              id='textarea'
              rows='10'
              placeholder='text or message ...'
              defaultValue={messageDetails.message.message} // Set the initial value
              {...register("message", { required: true })}
            ></textarea>
          <span style={{ marginLeft: "2.5rem", color: "red" }}>
            {errors.message?.type === "required" && "Message Is Required *"}
          </span>
        </div>
      <div>
  <label style={{marginLeft:"4rem"}}>Avatar Address:</label>
  {messageDetails.message.avatar}
  <input
    type='file'
    name="avatar"
    className='choose-btn'
    {...register("avatar", { required: true })}
  />
  <span style={{ marginLeft: "2.5rem", color: "red" }}>
    {errors.avatar?.type === "required" && "Please Select Image *"}
  </span>
</div>
        <div className='text-center mb-4'>
          <button type='submit' className='submit-btn '>
            Update
          </button>
        </div>
      </form>
      )}
    </div>
  </>
  );
};

export default UpdateMessage;
