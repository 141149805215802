import React, { useState, useEffect } from "react";
import "./Members.css";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import "./MessageOutput.css";
import { useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2"; // Import sweetalert2

const MeetingsOutput = () => {
  const [meetingsData, setMeetingsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const history = useHistory();

  useEffect(() => {
    async function fetchMeetings(page) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/meetingsinfo?page=${page}`);
        const data = await response.json();
        setMeetingsData(data.MeetingsData.reverse());
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchMeetings(currentPage);
  }, [currentPage]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const redirect = () => {
    history.push("/admin/meetings");
  };
 

  const deleteMessage = async (id) => {
    try {
      const result = await fetch(`${process.env.REACT_APP_BASE_URL}/meetingsinfo/${id}`, {
        method: "DELETE",
      });
      if (result.ok) {
        // Message deleted successfully
        setMeetingsData(meetingsData.filter((data) => data._id !== id));
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "The message has been deleted.",
        });
      }
    } catch (error) {
      console.log("Something went wrong:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while deleting the message.",
      });
    }
  };

  const confirmDelete = (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This message will be permanently deleted.",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMessage(id);
      }
    });
  };
  const searchHandle = async (event) => {
    const searchValue = event.target.value; // Get the value from the input field
    console.warn(searchValue);
    if(searchValue){
  
    try {
      let result = await fetch(`${process.env.REACT_APP_BASE_URL}/meetingsinfo/search/${searchValue}`);
      result = await result.json();
      if (result) {
        setMeetingsData(result);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  }else{
    // setMeetingsData(meetingsData);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/meetingsinfo`);
      const data = await response.json();
      setMeetingsData(data.MeetingsData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  
  };
  

  return (
    <>
      <h1 className="text-center">Meetings</h1>
      <div className="text-center">
      <input type="search" placeholder="search by unit name..." className="search-input"
      onChange={searchHandle}/>
      </div>
      <div className="main-member-div">

      {Array.isArray(meetingsData) && meetingsData.length > 0 ? (
          meetingsData.map((data, index) => (
            
          <div className="members-output" key={index}>
            <div>
              <img
                className=" img-fluid output-img"
                src={`${process.env.REACT_APP_BASE_URL}/${data.avatar}`}
                alt=""
              />

              {/* <img className="members-img" src={imge} alt='' style={{width:"100%"}}/> */}
            </div>
            <div>
            <p className="text-center">{data.unit}</p>
              <h3 className="text-center">{data.title}</h3>
              <h4 className="text-center">{data.button}</h4>
              <p className=" text-center">{data.text}</p>
            </div>
            <div className="icons-div-member ">
              <AddCircleOutlineOutlinedIcon
                onClick={redirect}
                style={{ height: "2rem", width: "2rem", cursor: "pointer" }}
              />
              <Link
              to={`/admin/meetingsUpdate/${data._id}`}
              style={{ color: "white" }}
            >
              <EditOutlinedIcon
                style={{ height: "2rem", width: "2rem", cursor: "pointer" }}
              />
            </Link>
              <DeleteOutlineOutlinedIcon
                onClick={() => confirmDelete(data._id)}
                style={{ height: "2rem", width: "2rem", cursor: "pointer" }}
              />
            </div>
          </div>
         
        ))
      ) : (
        <>
        <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
        <div><p>No messages available.</p>
        <p>May be API error</p>
        <p>No Data</p></div>
        <div><h1 className="text-center">No Search Result Found</h1></div>
        <div><h1 onClick={redirect} style={{cursor:"pointer",color:"blue",textDecoration:"underline"}}>Add data</h1>
        </div>
        </div>
        </>
      )}
       </div>
       
       <ul className="pagination mt-5 d-flex justify-content-center">
  {Array.from({ length: totalPages }).map((_, index) => (
    <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
      <button
        className={`page-link ${currentPage === index + 1 ? 'active-btn' : ''}`}
        onClick={() => paginate(index + 1)}
      >
        {index + 1}
      </button>
    </li>
  ))}
</ul>
      
    </>
  );
};

export default MeetingsOutput;
