import React, { useState, useEffect } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import "./MessageOutput.css";
import { useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2"; // Import sweetalert2

const HomeMessageOutput = () => {
  const [ourteamData, setOurteamData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/message`);
        const data = await response.json();
        setOurteamData(data.allmessages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const redirect = () => {
    history.push("/admin/homemessage");
  };

  const deleteMessage = async (id) => {
    try {
      const result = await fetch(`${process.env.REACT_APP_BASE_URL}/message/${id}`, {
        method: "DELETE",
      });
      if (result.ok) {
        // Message deleted successfully
        setOurteamData(ourteamData.filter((data) => data._id !== id));
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "The message has been deleted.",
        });
      }
    } catch (error) {
      console.log("Something went wrong:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while deleting the message.",
      });
    }
  };

  const confirmDelete = (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This message will be permanently deleted.",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMessage(id);
      }
    });
  };

  return (
    <>
      <h1 className="text-center">Messages Output</h1>
      {Array.isArray(ourteamData) && ourteamData.length > 0 ? (
         ourteamData.map((data, index) => (
      // {ourteamData.map((data, index) => (
        <div className="message-output" key={index}>
          <div>
            <img
              className=" img-fluid output-img"
              src={`${process.env.REACT_APP_BASE_URL}/${data.avatar}`}
              alt=""
            />
          </div>
          <div>
            <h3 className="text">{data.name}</h3>
            <h4 className="text">{data.designation}</h4>
            <p className="output-para">{data.message}</p>
          </div>
          <div className="icons-div">
            <AddCircleOutlineOutlinedIcon
              onClick={redirect}
              style={{ height: "3rem", width: "3rem", cursor: "pointer" }}
            />
            <Link
              to={`/admin/messageUpdate/${data._id}`}
              style={{ color: "black" }}
            >
              <EditOutlinedIcon
                style={{ height: "3rem", width: "3rem", cursor: "pointer" }}
              />
            </Link>
            <DeleteOutlineOutlinedIcon
              onClick={() => confirmDelete(data._id)}
              style={{ height: "3rem", width: "3rem", cursor: "pointer" }}
            />
          </div>
        </div>
      ))
      ) : (
        <>
        <p>No messages available.</p>
        <p>May be API error</p>
        <p>No Data</p>
        <h1 onClick={redirect} style={{cursor:"pointer",color:"blue",textDecoration:"underline"}}>Add data</h1>
        </>
        )}
    </>
  );
};

export default HomeMessageOutput;
