import React, { useEffect, useState } from 'react';
import './Home.css'
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import swal from 'sweetalert2';
import { useForm } from "react-hook-form";


const LahoreMembersUpdate = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [messageDetails, setMessageDetails] = useState({
   name: '',
   designation: '',
   phone: '',
   avatar: ''// You may want to store the image URL here
  });

  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();

  useEffect(() => {
    getMessageDetails();
  }, []);

  const getMessageDetails = async () => {
    try {
      const result = await fetch(`${process.env.REACT_APP_BASE_URL}/lahoreMembers/${params.id}`);
      const data = await result.json();
      setMessageDetails(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching message details:", error);
    }
  }
  console.log(messageDetails);
//   console.log("Title",messageDetails.slider.title)
//   console.log("avatar",messageDetails.slider.avatar)

  
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("designation", data.designation);
      formData.append("phone", data.phone);
      formData.append("avatar", data.avatar[0]); // Assuming you allow only one file
  
      const result = await fetch(`${process.env.REACT_APP_BASE_URL}/lahoreMembers/${params.id}`, {
        method: 'PUT',
        body: formData,
      });
  
      const response = await result.json();
      console.log(response);
  
      swal.fire({
        position: "center",
        icon: "success",
        title: "Member is Updated successfully!",
        showConfirmButton: true,
        timer: 5000,
      });
      history.push('/admin/teamlahoreoutput');
    } catch (error) {
      console.error("Error updating message:", error);
    }
  };
  
  

  if (isLoading) {
    return <div>Loading...</div>; // Display a loading indicator while fetching data
  }

  return (
    <>
    <div className='home-main-div'>
      <h1 className='text-center'>Update Lahore Members Form</h1>
      {messageDetails.name !== '' && messageDetails.designation !== '' && messageDetails.phone !=='' && messageDetails.avatar!=='' && (
      // <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        
      <input
            type='text'
            placeholder='Enter The Member Name...'
            defaultValue={messageDetails.slider.name} // Set the initial value
            {...register("name", { required: true })}
            className='formTitle'
          />
          
        <span style={{ marginLeft: "2.5rem", color: "red" }}>
          {errors.name?.type === "required" && "Member name Is Required *"}
        </span>
        <input
            type='text'
            placeholder='Enter Member Designation...'
            defaultValue={messageDetails.slider.designation} // Set the initial value
            {...register("designation", { required: true })}
            className='formTitle'
          />
        <span style={{ marginLeft: "2.5rem", color: "red" }}>
          {errors.designation?.type === "required" && "Member Designation Is Required *"}
        </span>
        <input
            type='text'
            placeholder='Enter Member Phone...'
            defaultValue={messageDetails.slider.phone} // Set the initial value
            {...register("phone", { required: true })}
            className='formTitle'
          />
        <span style={{ marginLeft: "2.5rem", color: "red" }}>
          {errors.phone?.type === "required" && "Member Phone Is Required *"}
        </span>
      <div>
  <label style={{marginLeft:"4rem"}}>Avatar Address:</label>
  {messageDetails.slider.avatar}
  <input
    type='file'
    name="avatar"
    className='choose-btn'
    {...register("avatar", { required: true })}
  />
  <span style={{ marginLeft: "2.5rem", color: "red" }}>
    {errors.avatar?.type === "required" && "Please Select Image *"}
  </span>
</div>
        <div className='text-center mb-4'>
          <button type='submit' className='submit-btn '>
            Update
          </button>
        </div>
      </form>
      )}
    </div>
  </>
  );
};

export default LahoreMembersUpdate;
