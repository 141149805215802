import React, { useEffect, useState } from 'react';
import './Home.css'
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import swal from 'sweetalert2';
import { useForm } from "react-hook-form";


const AuditsUpdate = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [messageDetails, setMessageDetails] = useState({
    title: '',
    unit: '',
    avatar: '', // You may want to store the image URL here
  });

  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();

  useEffect(() => {
    getMessageDetails();
  }, []);

  const getMessageDetails = async () => {
    try {
      const result = await fetch(`${process.env.REACT_APP_BASE_URL}/auditImages/${params.id}`);
      const data = await result.json();
      setMessageDetails(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching message details:", error);
    }
  }
  console.log(messageDetails);
//   console.log("Title",messageDetails.slider.title)
//   console.log("avatar",messageDetails.slider.avatar)

  
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("title", data.title);
    //   formData.append("unit", data.unit);
      formData.append("avatar", data.avatar[0]); // Assuming you allow only one file
  
      const result = await fetch(`${process.env.REACT_APP_BASE_URL}/performancereport/${params.id}`, {
        method: 'PUT',
        body: formData,
      });
  
      const response = await result.json();
      console.log(response);
  
      swal.fire({
        position: "center",
        icon: "success",
        title: "Slide is Updated successfully!",
        showConfirmButton: true,
        timer: 5000,
      });
      history.push('/admin/auditsOutput');
    } catch (error) {
      console.error("Error updating message:", error);
    }
  };
  
  

  if (isLoading) {
    return <div>Loading...</div>; // Display a loading indicator while fetching data
  }

  return (
    <>
    <div className='home-main-div'>
      <h1 className='text-center'>Update Audits Form</h1>
      {messageDetails.title !== '' && messageDetails.unit !== '' && messageDetails.avatar!=='' && (
      // <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">

      {/* <input
            type='text'
            placeholder='Enter The Unit Name...'
            defaultValue={messageDetails.slider.unit} // Set the initial value
            {...register("unit", { required: true })}
            className='formTitle'
          />
          
        <span style={{ marginLeft: "2.5rem", color: "red" }}>  
        {errors.unit?.type === "required" && "Unit name Is Required *"}
        </span> */}
      <input
            type='text'
            placeholder='Enter performance Report Title...'
            defaultValue={messageDetails.Meetings.title} // Set the initial value
            {...register("title", { required: true })}
            className='formTitle'
          />
          
        <span style={{ marginLeft: "2.5rem", color: "red" }}>
          {errors.title?.type === "required" && "Member name Is Required *"}
        </span>
        {/* <div>
        <textarea
              className='formTextarea'
              name='textarea'
              id='textarea'
              rows='10'
              placeholder='text or message ...'
              defaultValue={messageDetails.Meetings.text} // Set the initial value
              {...register("text", { required: true })}
            ></textarea>
          <span style={{ marginLeft: "2.5rem", color: "red" }}>
            {errors.text?.type === "required" && "Message Is Required *"}
          </span>
        </div> */}
      <div>
  <label style={{marginLeft:"4rem"}}>Avatar Address:</label>
  {messageDetails.Meetings.avatar}
  <input
    type='file'
    name="avatar"
    className='choose-btn'
    {...register("avatar", { required: true })}
  />
  <span style={{ marginLeft: "2.5rem", color: "red" }}>
    {errors.avatar?.type === "required" && "Please Select Image *"}
  </span>
</div>
        <div className='text-center mb-4'>
          <button type='submit' className='submit-btn '>
            Update
          </button>
        </div>
      </form>
      )}
    </div>
  </>
  );
};

export default AuditsUpdate;
