import React from "react";

// react-bootstrap components
import { Badge, Button, Navbar, Nav, Container } from "react-bootstrap";

function Maps() {
//   const mapRef = React.useRef(null);
//   React.useEffect(() => {
//     let google = window.google;
// let map = mapRef.current;
// let lat = "31.574950016507028";
// let lng = "74.34952105522214";
// const myLatlng = new google.maps.LatLng(lat, lng);
// const mapOptions = {
//   zoom: 13,
//   center: myLatlng,
//   scrollwheel: false,
//   zoomControl: true,
// };

// map = new google.maps.Map(map, mapOptions);

//     const marker = new google.maps.Marker({
//       position: myLatlng,
//       map: map,
//       animation: google.maps.Animation.DROP,
//       title: "Light Bootstrap Dashboard PRO React!",
//     });

//     const contentString =
//       '<div class="info-window-content"><h2>Light Bootstrap Dashboard PRO React</h2>' +
//       "<p>A premium Admin for React-Bootstrap, Bootstrap, React, and React Hooks.</p></div>";

//     const infowindow = new google.maps.InfoWindow({
//       content: contentString,
//     });

//     google.maps.event.addListener(marker, "click", function () {
//       infowindow.open(map, marker);
//     });
//   }, []);
  return (
    <>
      <div className="map-container">
      <iframe  title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3399.1469334508943!2d74.34687927408358!3d31.575018344442846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39191b32b8b5f619%3A0xcb9d418b6742d6c4!2sAghaz%20e%20Safar%20Foundation!5e0!3m2!1sen!2s!4v1685468350656!5m2!1sen!2s" 
        style={{width:"90vw",height:"70vh",border:"none",allowfullscreen:"", loading:"lazy"}} referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </>
  );
}

export default Maps;
