import React from 'react'
import './Home.css'
import swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';

const HomeMessage = () => {
  const history = useHistory();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm();
     const onSubmit = async (data) => {
        try {
          const formData = new FormData();
          formData.append("name", data.name);
          formData.append("designation", data.designation);
          formData.append("message", data.message);
          formData.append("avatar", data.avatar[0]); // Assuming you are uploading a single file
      
          let result = await fetch(`${process.env.REACT_APP_BASE_URL}/message`, {
            method: "post",
            body: formData,
          });
      
          if (!result.ok) {
            throw new Error("Network response was not ok");
          }
      
          result = await result.json();
          console.log("Form submitted!", result);
      
          swal.fire({
            position: "center",
            icon: "success",
            title: "Message is added successfully!",
            showConfirmButton: true,
            timer: 5000,
          });
          // alert("Success! Data is sent");
          reset();
          history.push('/admin/messageoutput');
        } catch (error) {
          console.error("Error submitting form:", error);
          // Handle the error, show an error message to the user, etc.
        }
      };
      
    
      return (
        <>
          <div className='home-main-div'>
            <h1 className='text-center'>Home Message Form</h1>
            <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
              <input
                type='text'
                placeholder='Enter The Slide Title...'
                {...register("name", { required: true })}
                className='formTitle'
              />
              <span style={{ marginLeft: "2.5rem", color: "red" }}>
                {errors.name?.type === "required" && "Name or Title Is Required *"}
              </span>
              <input
                type='text'
                placeholder='Enter Sub_Title...'
                {...register("designation", { required: true })}
                className='formTitle'
              />
              <span style={{ marginLeft: "2.5rem", color: "red" }}>
                {errors.designation?.type === "required" && "Sub_Title Is Required *"}
              </span>
              <div>
                <textarea
                  className='formTextarea'
                  name='textarea'
                  id='textarea' // Use 'textarea' as the name and id
                  rows='10'
                  placeholder='text or message ...'
                  {...register("message", { required: true })}
                ></textarea>
                <span style={{ marginLeft: "2.5rem", color: "red" }}>
                  {errors.message?.type === "required" && "Message Is Required *"}
                </span>
              </div>
            <input
  type='file'
  name="avatar"  // Change this line to "avatar"
  className='choose-btn'
  {...register("avatar", { required: true })}
/>
            <span style={{ marginLeft: "2.5rem", color: "red" }}>
              {errors.avatar?.type === "required" && "Please Select Image *"}
            </span>
              <div className='text-center mb-4'>
                <button type='submit' className='submit-btn '>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </>
      );
    };


export default HomeMessage