import React from 'react'
import './Home.css'
import swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';

const AuditsForm = () => {
  const history = useHistory();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("title", data.title);
    //   formData.append("unit", data.unit);
      formData.append("avatar", data.avatar[0]); // Assuming you are uploading a single file
  
      let result = await fetch(`${process.env.REACT_APP_BASE_URL}/auditImages`, {
        method: "post",
        body: formData,
      });
  
      if (!result.ok) {
        throw new Error("Network response was not ok");
      }
  
      result = await result.json();
      console.log("Form submitted!", result);
  
      swal.fire({
        position: "center",
        icon: "success",
        title: "Performance Report Projects Added",
        showConfirmButton: true,
        timer: 5000,
      });
      // alert("Success! Data is sent");
      reset();
          history.push('/admin/auditsOutput');
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle the error, show an error message to the user, etc.
    }
  };
  

  return (
    <>
      <div className='home-main-div'>
        <h1 className='text-center'>Audits Form</h1>
        <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
          <input
            type='text'
            placeholder='Enter Your Title...'
            {...register("title", { required: true })}
            className='formTitle'
          />
          <span style={{ marginLeft: "2.5rem", color: "red" }}>
            {errors.title?.type === "required" && "Title is required"}
          </span>
          <input
          type='file'
          name="avatar"
          className='choose-btn'
          {...register("avatar", { required: true })}
        />
        <span style={{ marginLeft: "2.5rem", color: "red" }}>
          {errors.avatar?.type === "required" && "Please Select image"}
        </span>
          <div className='text-center mb-4'>
            <button type='submit' className='submit-btn '>
              Submit
            </button>
          </div>
        </form>
      </div>
      
    </>
  );
};


export default AuditsForm;