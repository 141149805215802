import React from "react";
import "./Home.css";
import swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

const ProjectsDetailsForm = () => {
  const history = useHistory();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("unit", data.unit);
      formData.append("text", data.text);

      // Append each file separately
      for (const file of data.avatar) {
        formData.append("avatar", file);
      }
      console.log(data.avatar);
      let result = await fetch(`${process.env.REACT_APP_BASE_URL}/projectsdetails`, {
        method: "post",
        body: formData,
      });
      

      if (!result.ok) {
        throw new Error("Network response was not ok");
      }

      result = await result.json();
      console.log("Form submitted!", result);

      swal.fire({
        position: "center",
        icon: "success",
        title: "Project Info is added successfully!",
        showConfirmButton: true,
        timer: 5000,
      });
      // alert("Success! Data is sent");
      reset();
      history.push("/admin/projectsDetailsOutput");
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle the error, show an error message to the user, etc.
    }
  };
  return (
    <>
      <div className="home-main-div">
        <h1 className="text-center">Projects details Form</h1>
        <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
          <input
            type="text"
            placeholder="Enter Your Project Title..."
            {...register("title", { required: true })}
            className="formTitle"
          />
          <span style={{ marginLeft: "2.5rem", color: "red" }}>
            {errors.title?.type === "required" && "Title is required *"}
          </span>
          <input
            type="text"
            placeholder="Enter Your Unit Name..."
            {...register("unit", { required: true })}
            className="formTitle"
          />
          <span style={{ marginLeft: "2.5rem", color: "red" }}>
            {errors.unit?.type === "required" && "Unit Name is required *"}
          </span>
          <div>
            <textarea
              className="formTextarea"
              name="textarea"
              id="textarea" // Use 'textarea' as the name and id
              rows="10"
              placeholder="text or message ..."
              {...register("text", { required: true })}
            ></textarea>
            <span style={{ marginLeft: "2.5rem", color: "red" }}>
              {errors.text?.type === "required" && "Text-Area is required *"}
            </span>
          </div>
          <input
            type="file"
            multiple
            name="avatar"
            className="choose-btn"
            {...register("avatar", { required: true })}
          />
          <span style={{ marginLeft: "2.5rem", color: "red" }}>
            {errors.avatar?.type === "required" && "Please Select image *"}
          </span>
          <div className="text-center mb-4">
            <button type="submit" className="submit-btn ">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProjectsDetailsForm;
