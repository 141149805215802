import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Swal from "sweetalert2";

import { useHistory } from "react-router-dom";

const LahoreFundsoutput = () => {
  const [data, setData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/lahoreFunds`);
        if (response.ok) {
          const fetchedData = await response.json();
          console.log(fetchedData); // Add this line to check fetched data
          setData(fetchedData.allmessages.reverse()); // Use the correct property
        } else {
          console.error("Error fetching data:", response.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run once on mount

  const redirect = () => {
    history.push("/admin/lahorefunds");
  };

  const deleteMessage = async (id) => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_BASE_URL}/lahoreFunds/${id}`,
        {
          method: "DELETE",
        }
      );
      if (result.ok) {
        // Message deleted successfully
        setData(data.filter((dataItem) => dataItem._id !== id)); // Fix here
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "The message has been deleted.",
        });
      }
    } catch (error) {
      console.log("Something went wrong:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while deleting the message.",
      });
    }
  };

  const confirmDelete = (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This message will be permanently deleted.",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMessage(id);
      }
    });
  };

  const getRowColor = (index) => (index % 2 === 0 ? "white" : "offwhite");

  return (
    <div className="container mt-5 report-bg p-5 mb-5">
        <h3 className="unit-name text-center">
          Funds Position Of Lahore (Unit)
        </h3>
        {data.length === 0 ? ( // Check if the data array is empty
        <div className="text-center">
          <p>No data available.</p>
          <AddIcon
            onClick={redirect}
            style={{
              color: "green",
              height: "5rem",
              width: "5rem",
              cursor: "pointer",
            }}
          />
        </div>
      ) : (
      <div>
        
        {data.map((yearData, yearIndex) => (
          <div key={yearIndex}>
            <div
              className="text-center heading-report"
              style={{ borderBottom: "2px solid black", marginLeft: "auto" }}
            >
              <strong>{yearData.year}</strong>
            </div>
            <figure className="wp-block-table alignfull is-style-stripes">
              <table
                className="table-striped main-table"
                style={{ backgroundColor: "f3f4f5" }}
              >
                {/* Table header */}
                <thead>
                  <tr>
                    <th className="has-text-align-center" data-align="center">
                      MONTH
                    </th>
                    <th
                      className="has-text-align-center table-heading"
                      data-align="center"
                    >
                      INCOME
                    </th>
                    <th
                      className="has-text-align-center table-heading"
                      data-align="center"
                    >
                      EXPENSES
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="table-body">
                  {yearData.month.map((month, monthIndex) => (
                    <tr key={monthIndex} className={getRowColor(monthIndex)}>
                      <td
                        className="has-text-align-center months"
                        data-align="center"
                      >
                        {month}
                      </td>
                      <td className="has-text-align-center" data-align="center">
                        {yearData.income[monthIndex]}
                      </td>
                      <td className="has-text-align-center" data-align="center">
                        {yearData.expenses[monthIndex]}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </figure>
            <AddIcon
              onClick={redirect}
              style={{
                color: "green",
                height: "5rem",
                width: "5rem",
                cursor: "pointer",
              }}
            />
            <DeleteOutlineOutlinedIcon
              onClick={() => confirmDelete(yearData._id)} // Fix here
              style={{
                height: "5rem",
                width: "5rem",
                cursor: "pointer",
                color: "red",
              }}
            />
            <hr />
          </div>
        ))}
      </div>
      )}
    </div>
  );
}

export default LahoreFundsoutput