// // ************************************************************************************
import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import AdminLayout from "layouts/Admin.js";
import SignIn from './SignIn' // Import your SignIn component here

const root = ReactDOM.createRoot(document.getElementById("root"));

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/signin"
          render={(props) => (
            <SignIn {...props} setAuthenticated={setAuthenticated} />
          )}
        />
        {/* <Route
  path={prop.layout + prop.path}
  render={(props) => <prop.component {...props} />}
  key={key}
/> */}
        <Route
          path="/admin"
          render={(props) =>
            authenticated ? (
              <AdminLayout {...props} />
            ) : (
              <Redirect to="/signin" />
            )
          }
        />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </BrowserRouter>
  );
}

root.render(<App />);
// 8888888888888888888888888888888888888888888888888888888888888888888888888888888888888877777777777777777777777777777777777777777777777777777
// import React from "react";
// import ReactDOM from "react-dom/client";

// import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "./assets/css/animate.min.css";
// import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
// import "./assets/css/demo.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";

// import AdminLayout from "layouts/Admin.js";

// const root = ReactDOM.createRoot(document.getElementById("root"));

// root.render(
//   <BrowserRouter>
//     <Switch>
//       <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
//       <Redirect from="/" to="/admin/dashboard" />
//     </Switch>
//   </BrowserRouter>
// );